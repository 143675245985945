/**
 * ==============
 * Section 1 (Hero section)
 * ==============
 */

#hero-video {
    z-index: -20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

#section-1 {
    box-shadow:
        0px 0px 0px 0px #00000040,
        inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
}

@media (min-width: 1024px) {
    #section-1 {
        box-shadow:
            0px 0px 0px 0px #00000040,
            inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
    }

    #section-1 #play-button {
        right: 0;
        bottom: 15%;
    }
}

@media (min-width: 1280px) {
    #section-1 #play-button {
        bottom: 25%;
    }
}

/**
 * ==============
 * Section 2 (Companies or Projects)
 * ==============
 */

#proyectos {
    background: linear-gradient(to bottom, white, rgb(229 231 235));
}

/**
 * ==============
 * Section 3
 * ==============
 */

.list-disc-red li::before {
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

/**
 * ==============
 * Section 11 (Contact form)
 * ==============
 */

input[name="phone"]::-webkit-outer-spin-button,
input[name="phone"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[name="phone"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

select[name="type-of-lead"]::-ms-expand {
    display: none;
}

select[name="type-of-lead"] {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='rgb(156 163 175)' width='1.5rem' height='1.5rem'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
    background-size: 1.5rem;
    background-position: calc(100% - 1rem);
    background-repeat: no-repeat;
}
