#hero-video {
  z-index: -20;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

#section-1 {
  box-shadow: 0 0 #00000040, inset 0 0 0 1000px #0009;
}

@media (width >= 1024px) {
  #section-1 {
    box-shadow: 0 0 #00000040, inset 0 0 0 1000px #0000004d;
  }

  #section-1 #play-button {
    bottom: 15%;
    right: 0;
  }
}

@media (width >= 1280px) {
  #section-1 #play-button {
    bottom: 25%;
  }
}

#proyectos {
  background: linear-gradient(#fff, #e5e7eb);
}

.list-disc-red li:before {
  content: "•";
  color: red;
  width: 1em;
  margin-left: -1em;
  font-weight: bold;
  display: inline-block;
}

input[name="phone"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[name="phone"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[name="phone"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

select[name="type-of-lead"]::-ms-expand {
  display: none;
}

select[name="type-of-lead"] {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='rgb(156 163 175)' width='1.5rem' height='1.5rem'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A") calc(100% - 1rem) / 1.5rem no-repeat;
}
/*# sourceMappingURL=index.3d10728d.css.map */
